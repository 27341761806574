import firehydrantAPI from 'helpers/firehydrantAPI';
import { useMutation, useQuery } from 'react-query';

export const SIGNAL_ESCALATION_POLICY_DETAIL =
  'SIGNAL_ESCALATION_POLICY_DETAIL';

export const SIGNAL_ESCALATION_POLICY_LIST = 'SIGNAL_ESCALATION_POLICY_LIST';

export const useEscalationPolicies = (teamId, params = {}, options) => {
  const query = useQuery(
    [SIGNAL_ESCALATION_POLICY_LIST, teamId],
    () =>
      firehydrantAPI.get(`teams/${teamId}/escalation_policies`, {
        params: { ...params },
      }),
    {
      enabled: Boolean(teamId),
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.data?.data || [],
    pagination: query?.data?.data?.pagination || {},
  };
};

export const useEscalationPolicy = (teamId, policyId, params = {}, options) => {
  const query = useQuery(
    [SIGNAL_ESCALATION_POLICY_DETAIL, teamId, policyId],
    () =>
      firehydrantAPI.get(`teams/${teamId}/escalation_policies/${policyId}`, {
        params: { ...params },
      }),
    {
      enabled: Boolean(teamId && policyId),
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.data || {},
  };
};

const createEscalationPolicy = (body) =>
  firehydrantAPI.post(`teams/${body.teamId}/escalation_policies`, body);

export const useCreateEscalationPolicy = (body) => {
  return useMutation(createEscalationPolicy, body);
};

const updateEscalationPolicy = (body) =>
  firehydrantAPI.patch(
    `teams/${body.teamId}/escalation_policies/${body.id}`,
    body,
  );

export const useUpdateEscalationPolicy = (body) => {
  return useMutation(updateEscalationPolicy, body);
};

const deleteEscalationPolicy = (body) =>
  firehydrantAPI.delete(
    `teams/${body.teamId}/escalation_policies/${body.policyId}`,
  );

export const useDeleteEscalationPolicy = (body) => {
  return useMutation(deleteEscalationPolicy, body);
};
