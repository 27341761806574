import { useMutation, useQuery } from 'react-query';

import internalAPI from '../../helpers/internalAPI';

export function useUserLinkedAccounts() {
  const {
    data: requestData = { data: [] },
    error,
    isLoading,
  } = useQuery(['user_account'], () =>
    internalAPI.get('/internal_api/v1/oauth_providers'),
  );

  return {
    data: requestData?.data,
    errors: error,
    isLoading,
  };
}

export function useAlternativeEmails() {
  const {
    data: requestData = { data: [] },
    error,
    isLoading,
    refetch,
  } = useQuery(['user_alternative_emails'], () =>
    internalAPI.get('/internal_api/v1/alternative_emails'),
  );

  return {
    data: requestData?.data.data,
    errors: error,
    isLoading,
    refetch,
  };
}
const resendVerificationEmail = (body) =>
  internalAPI.post(
    `/internal_api/v1/alternative_emails/${body.id}/resend`,
    body,
  );

export const useResendVerificationEmail = (body) => {
  return useMutation(resendVerificationEmail, body);
};

const createAlternativeEmail = (body) =>
  internalAPI.post('/internal_api/v1/alternative_emails', body);

export const useCreateAlternativeEmail = (body) => {
  return useMutation(createAlternativeEmail, body);
};

const deleteAlternativeEmail = (id) =>
  internalAPI.delete(`/internal_api/v1/alternative_emails/${id}`);

export const useDeleteAlternativeEmail = (body) => {
  return useMutation(deleteAlternativeEmail, body);
};
