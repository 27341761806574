import React, { useEffect, useState } from 'react';

import { Card, Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'helpers/SPAWrapper';
import { useOrgNavigate } from 'hooks/useOrgNavigate';
import { useOnboardingStep } from 'queries/onboardingTasks';
import {
  useCreateSignalsNotification,
  useDeleteSignalsNotification,
  useSignalNotifications,
  useUpdateSignalsNotification,
} from 'queries/signalsNotifications';
// queries
import * as Yup from 'yup';

// components
import FormV2 from 'components/common/Form/FormV2/FormV2';
import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';

import { NotificationRow } from './SignalNotificationRow';
import { NotificationEditRow } from './SignalsNotificationEditRow';
import {
  generateInitialValues,
  generateNotificationOptions,
  timeOptions,
} from './helpers/signalsNotificationFormHelpers';

const validationSchema = Yup.object().shape({
  notifications: Yup.array().of(
    Yup.object().shape({
      notification_type: Yup.object().required('Required'),
      delay: Yup.object().required('Required'),
    }),
  ),
});

// @param {Object} notificationPriority - Object containing the notification priority ("HIGH", "MED", "LOW")
// This component will only render notifications for the given notification priority set
const SignalsNotificationsForm = ({
  notificationPriority = 'HIGH',
  destinations = [],
}) => {
  const { addToast } = useToastContext();
  const { data: signalsNotifications, isLoading } = useSignalNotifications({
    notificationPriority,
  });

  // Filters out notifications that are not of the given notification priority
  const filteredNotifications = signalsNotifications?.filter(
    (destination) => destination.notification_priority === notificationPriority,
  );

  const {
    isSignalsOnboarding,
    completeOnboardingStep,
    isStepComplete,
    isLoading: onboardingIsLoading,
  } = useOnboardingStep('configure_notification_preferences');
  const navigate = useOrgNavigate();
  const notificationOptions = generateNotificationOptions(destinations);

  // If you already have a notification setting and are onboarding, complete the step
  useEffect(() => {
    if (
      isSignalsOnboarding &&
      !onboardingIsLoading &&
      !isStepComplete &&
      filteredNotifications?.length > 0
    ) {
      completeOnboardingStep();
    }
  }, [isSignalsOnboarding, onboardingIsLoading, filteredNotifications]);

  const [editingIndex, setEditingIndex] = useState(null);

  const onSuccess = () => {
    if (isSignalsOnboarding && !isStepComplete) {
      completeOnboardingStep();
    }
    if (isSignalsOnboarding) {
      navigate('/overview');
    }
    addToast({
      message: 'Notification setting has been updated.',
      variant: 'success',
    });
    setEditingIndex(null);
    queryClient.invalidateQueries('signals_notification_settings');
  };

  const onDeleteSuccess = () => {
    addToast({
      message: 'Notification setting has been deleted.',
      variant: 'success',
    });
    setEditingIndex(null);
    queryClient.invalidateQueries('signals_notification_settings');
  };

  const onError = () => {
    addToast({
      message: 'Something went wrong updating notification settings.',
      variant: 'alert',
    });
  };

  const { mutate: createNotification } = useCreateSignalsNotification({
    onSuccess,
    onError,
  });
  const { mutate: updateNotification } = useUpdateSignalsNotification({
    onSuccess,
    onError,
  });
  const { mutate: deleteNotification } = useDeleteSignalsNotification({
    onSuccess: onDeleteSuccess,
    onError,
  });

  const onSubmit = (values) => {
    const submitValues = values.notifications.map((item) => {
      return {
        notification_type: item.notification_type.type,
        destination: item.notification_type.value,
        delay: item.delay.value,
        id: item.id,
        notification_priority: notificationPriority,
      };
    })[editingIndex];

    if (submitValues.id) {
      return updateNotification(submitValues);
    } else {
      return createNotification(submitValues);
    }
  };

  const deletePreference = (item, index, removeFunction) => {
    if (item.id) {
      deleteNotification(item);
    } else {
      removeFunction(index);
    }
  };

  const duplicatePreference = (item) => {
    const newDelayIndex =
      timeOptions.findIndex((option) => option.value === item.delay.value) + 1;
    const newDelay = timeOptions[newDelayIndex].value;
    const copy = {
      notification_type: item.notification_type.notification_type,
      destination: item.notification_type.value,
      // add 5 minutes to the iso8601 duration
      delay: newDelay,
      notification_priority: notificationPriority,
    };

    createNotification(copy);
  };

  const toggleEditState = (index) => {
    if (editingIndex === index) {
      setEditingIndex(null);
    } else {
      setEditingIndex(index);
    }
  };

  const cancelEdit = (resetForm) => {
    resetForm();
    toggleEditState(null);
  };

  const initialValues = generateInitialValues(filteredNotifications);

  return (
    <div data-testid={`signals-notification-form-${notificationPriority}`}>
      <FormV2
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        requiredPermissions={['member', 'owner']}
        data-testid={`signals-notification-form-${notificationPriority}`}
      >
        {(formik) => {
          return (
            <>
              <FormV2.Group>
                <FormV2.FieldArray name="notifications" ml="0">
                  {(arrayHelpers) => {
                    return (
                      <>
                        {arrayHelpers.form.values.notifications.length ===
                          0 && (
                          <Card py="2.5" px="3" my="2" bgColor="yellow.50" borderColor="yellow.300" alignItems="center">
                            <Text size="6" mb="0" color="gray.700">
                              You have no notification preferences configured
                              for this priority. If an alert is routed to you with this priority, we will use the next highest available settings you have configured.
                            </Text>
                          </Card>
                        )}
                        {arrayHelpers.form.values.notifications.map(
                          (item, index) => (
                            <Card key={index} py="2.5" px="3" my="2">
                              <Flex alignItems="center">
                                {editingIndex === index ? (
                                  <NotificationEditRow
                                    index={index}
                                    notificationOptions={notificationOptions}
                                    item={item}
                                    formik={formik}
                                    cancelEdit={cancelEdit}
                                  />
                                ) : (
                                  <NotificationRow
                                    index={index}
                                    item={item}
                                    formik={formik}
                                    toggleEditState={toggleEditState}
                                    duplicatePreference={duplicatePreference}
                                    deletePreference={deletePreference}
                                    arrayHelpers={arrayHelpers}
                                    isLoading={isLoading}
                                  />
                                )}
                              </Flex>
                            </Card>
                          ),
                        )}
                        {editingIndex === null && (
                          <FormV2.ArrayAddButton
                            buttonText="Add notification step"
                            buttonProps={{
                              width: '100%',
                            }}
                            addAction={() => {
                              const lastElement =
                                arrayHelpers.form.values.notifications[
                                  arrayHelpers.form.values.notifications
                                    .length - 1
                                ];

                              if (
                                lastElement &&
                                (lastElement.notification_type === '' ||
                                  lastElement.delay === '')
                              ) {
                                addToast({
                                  message:
                                    'Please complete the previous notification before adding another.',
                                  variant: 'info',
                                });
                              } else {
                                if (editingIndex !== null) {
                                  formik.handleSubmit();
                                } else {
                                  arrayHelpers.push({
                                    notification_type: '',
                                    delay: '',
                                  });
                                  setEditingIndex(
                                    arrayHelpers.form.values.notifications
                                      .length,
                                  );
                                }
                              }
                            }}
                          />
                        )}
                      </>
                    );
                  }}
                </FormV2.FieldArray>
              </FormV2.Group>
            </>
          );
        }}
      </FormV2>
    </div>
  );
};

export default SignalsNotificationsForm;
