import React from 'react';

import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react';
import { IconButton } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

const Drawer = ({
  isOpen,
  size = 'xl',
  onClose,
  title,
  helperText,
  children,
  footerChildren,
  bodyStyling = {},
  disableBody = false,
  rightHeaderChildren = null,
  closeOnOverlayClick = true,
}) => {
  // This allows the animation to happen before it is ripped out of the DOM
  // It allows the page not to have 1 portal instead of n
  const [isClose, setIsClose] = React.useState(false);
  React.useEffect(() => {
    if (isOpen) {
      setIsClose(false);
    }
  }, [isOpen]);
  const handleOnClose = () => {
    setIsClose(true);
    setTimeout(() => {
      onClose();
    }, 200);
  };
  return (
    <ChakraDrawer
      isOpen={isOpen && !isClose}
      placement="right"
      size={size}
      onClose={handleOnClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <DrawerOverlay />
      {isOpen && (
        <DrawerContent>
          <DrawerHeader
            display="flex"
            alignItems="top"
            justifyContent="space-between"
            p="3"
            borderBottom="1px"
            borderBottomColor="grey.100"
          >
            <Box>
              {title && (
                <Text size="4" mb="0" isTruncated>
                  {title}
                </Text>
              )}
              {helperText && (
                <Text pt="1" size="6" my="0" color="gray.600" mb="0">
                  {helperText}
                </Text>
              )}
            </Box>
            {rightHeaderChildren}
            {!rightHeaderChildren && (
              <IconButton
                onClick={handleOnClose}
                name="close"
                variant="ghost"
              />
            )}
          </DrawerHeader>
          {disableBody ? (
            children
          ) : (
            <DrawerBody px="3" py="3" {...bodyStyling}>
              {children}
            </DrawerBody>
          )}
          {footerChildren && (
            <DrawerFooter
              width="100%"
              display="flex"
              borderTop="1px"
              borderTopColor="grey.100"
              p="0"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                maxWidth="1200px"
                mx="auto"
                flex="1"
                p="3"
              >
                {footerChildren}
              </Flex>
            </DrawerFooter>
          )}
        </DrawerContent>
      )}
    </ChakraDrawer>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  helperText: PropTypes.node,
  children: PropTypes.node,
  footerChildren: PropTypes.node,
  size: PropTypes.string,
  rightHeaderChildren: PropTypes.node,
  closeOnOverlayClick: PropTypes.bool,
};

export default Drawer;
