import firehydrantAPI from 'helpers/firehydrantAPI';
import { useMutation, useQuery } from 'react-query';

export const SIGNAL_RULES_LIST = 'SIGNAL_RULES_LIST';

export const useSignalRules = (teamId, params = {}, options) => {
  const query = useQuery(
    [SIGNAL_RULES_LIST, teamId],
    () =>
      firehydrantAPI.get(`teams/${teamId}/signal_rules`, {
        params: { ...params },
      }),
    { ...options },
  );

  return {
    ...query,
    data: query.data?.data || { fields: [] },
  };
};

export const useSignalRule = (body) => {
  const query = useQuery(
    ['rule', body.teamId, body.ruleId],
    () =>
      firehydrantAPI.get(`teams/${body.teamId}/signal_rules/${body.ruleId}`),
    {
      enabled: !!body.ruleId,
    },
  );

  return {
    ...query,
    data: query.data?.data,
  };
};

const createSignalRules = (body) =>
  firehydrantAPI.post(`teams/${body.teamId}/signal_rules`, body);

export const useCreateSignalRules = (body) => {
  return useMutation(createSignalRules, body);
};

const updateSignalRules = (body) =>
  firehydrantAPI.patch(`teams/${body.teamId}/signal_rules/${body.id}`, body);

export const useUpdateSignalRules = (body) => {
  return useMutation(updateSignalRules, body);
};

const deleteSignalRule = (body) =>
  firehydrantAPI.delete(
    `teams/${body.teamId}/signal_rules/${body.ruleId}`,
    body,
  );

export const useDeleteSignalRules = (body) => {
  return useMutation(deleteSignalRule, body);
};
