import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient as useQueryClientV5,
} from '@tanstack/react-query';

import {
  createQueryKeyFactory,
  isDetailQueryKey,
  isListQueryKey,
} from './dependencyBuilder';

// @param {array} key - The query key array to be used, generated by createQueryKeys
// @param {function} queryFn - The function to be used to fetch the data
// @param {object} options - The options object to be used
// @returns {object} - The query object
export const useApiQuery = (key, queryFn, options = {}) => {
  if (!isListQueryKey(key) && !isDetailQueryKey(key)) {
    throw new Error(`Invalid query key format: ${key}`);
  }

  const query = useQuery({
    queryKey: key,
    queryFn,
    ...options,
  });

  return {
    ...query,
  };
};

// @param {array} path - An array of objects representing the path to the resource,
// structured as [{ resource: 'resourceName', id: 'uuid' }, { resource: 'nestedResource'}],
// or [{ resource: 'resourceName' }]
// @param {object} filters - The filters to be used for the query
// @param {function} queryFn - The function to be used to fetch the data
// @param {object} options - The options object to be used
// @returns {object} - The query object
export const useListQuery = (path, filters, queryFn, options = {}) => {
  const key = [...createQueryKeyFactory(path), 'list', filters];
  const query = useApiQuery(key, queryFn, options);

  return {
    ...query,
    data: query.data?.data?.data ?? [],
    pagination: query.data?.data?.pagination ?? {},
  };
};

export const useInfiniteListQuery = (path, filters, queryFn, options = {}) => {
  const key = [...createQueryKeyFactory(path), 'list', filters];
  if (!isListQueryKey(key)) {
    throw new Error(`Invalid query key format for infinite query: ${key}`);
  }

  const query = useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam }) => queryFn(pageParam),
    getNextPageParam: (lastPage) => {
      if (!lastPage.data.after) return undefined;

      return lastPage.data.after;
    },
    ...options,
  });

  return {
    ...query,
    data: query.data?.pages?.flatMap((page) => page.data.data) ?? [],
  };
};

// @param {array} path - An array of objects representing the path to the resource,
// structured as [{ resource: 'resourceName', id: 'uuid' }, { resource: 'nestedResource'}],
// or [{ resource: 'resourceName' }]
// @param {string} id - The id to be used for the query, defaults to 'new'
// @param {function} queryFn - The function to be used to fetch the data
// @param {object} options - The options object to be used
// @returns {object} - The query object
export const useDetailQuery = (path, id = 'new', queryFn, options = {}) => {
  const key = [...createQueryKeyFactory(path), 'detail', id];
  const query = useApiQuery(key, queryFn, options);

  return {
    ...query,
    data: query.data?.data ?? {},
  };
};

export const createMutationHook = (mutationFn) => (options) =>
  useMutation({
    mutationFn,
    ...options,
  });

export const useQueryClient = useQueryClientV5;
