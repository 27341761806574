import React from 'react';

import { Box } from '@chakra-ui/react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  Text,
  TextField,
} from '@firehydrant/design-system';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const FormikTextInput = ({
  customError,
  forceShowError,
  formWidth,
  helperText,
  label,
  labelProps,
  maxLength,
  mb,
  name,
  rightChildren,
  leftIcon,
  type,
  validate,
  isRequired,
  ...props
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }) => {
        const isInvalid =
          ((meta.touched || forceShowError) && meta.error) || customError;
        return (
          <FormControl
            w={formWidth}
            mb={mb}
            isReadOnly={props?.isReadOnly}
            isInvalid={isInvalid}
          >
            {label && (
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormLabel color="grey.700" {...labelProps} id={name}>
                  {label}
                </FormLabel>
                {isRequired && (
                  <Text
                    as="span"
                    float="right"
                    fontSize="12px"
                    color="grey.600"
                  >
                    Required
                  </Text>
                )}
              </Flex>
            )}
            <InputGroup>
              {leftIcon && <Box mt={2}>{leftIcon}</Box>}
              <TextField
                maxLength={maxLength}
                type={type}
                {...field}
                {...props}
              />
              {rightChildren && (
                <InputRightElement>{rightChildren}</InputRightElement>
              )}
            </InputGroup>
            <Flex justifyContent="space-between">
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
              {maxLength && (
                <Text mt={1} mb={0} size={7} color="grey.500">
                  {`${field.value?.length}/${maxLength}`}
                </Text>
              )}
            </Flex>
            {(((meta.touched || forceShowError) && meta.error) ||
              customError) && (
              <FormErrorMessage color="red.500">
                {meta.error || customError}
              </FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

FormikTextInput.propTypes = {
  customError: PropTypes.string,
  forceShowError: PropTypes.bool,
  formWidth: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.shape({}),
  maxLength: PropTypes.number,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  rightChildren: PropTypes.element,
  type: PropTypes.string,
  validate: PropTypes.func,
  leftIcon: PropTypes.element,
  isRequired: PropTypes.bool,
};

FormikTextInput.defaultProps = {
  label: null,
  mb: 3,
  rightChildren: null,
  validate: undefined,
};

export default FormikTextInput;
