import React, { useState } from 'react';

import { Text } from '@firehydrant/design-system';
import _ from 'lodash';

import firehydrantAPI from '../../../../helpers/firehydrantAPI';

const MemberRow = ({ name, email }) => {
  return (
    <Text m="0" fontWeight="600">
      {name}
      <Text
        as="span"
        color="grey.700"
        fontSize="14px"
        m="0"
        ml="5px"
        fontWeight="normal"
      >
        {email}
      </Text>
    </Text>
  );
};
const userToOption = ({ id, name, email }) => {
  const type = 'user';
  return {
    label: <MemberRow name={name} email={email} />,
    value: { type, id },
  };
};

const scheduleToOption = ({ id, name }) => {
  return {
    label: name,
    value: { type: 'schedule', id },
  };
};

const groupSchedulesByIntegration = (schedules) => {
  // TODO: Need to backfill integrations on schedules so that we know which are PD.
  const groups = _.groupBy(
    schedules,
    ({ integration }) => integration || 'PagerDuty',
  );
  return _.map(groups, (scheduleList, integration) => ({
    label: `Active On-Call User From ${integration} Schedule`,
    options: scheduleList.map(scheduleToOption),
  }));
};

export const userAndScheduleSearchOptions = async (query) => {
  const [userResponse, scheduleResponse] = await Promise.all([
    firehydrantAPI.get('users', { params: { query } }),
    firehydrantAPI.getAllPages('schedules', { params: { query } }),
  ]);

  const userData = userResponse.data.data;
  const scheduleData = scheduleResponse;

  const userOptions = userData.map(userToOption);
  const scheduleOptions = groupSchedulesByIntegration(scheduleData);

  const options = [];
  scheduleOptions.forEach((item) => {
    options.push(item);
  });

  if (userOptions.length > 0) {
    options.push({
      label: 'Users',
      options: userOptions,
    });
  }
  return options;
};
