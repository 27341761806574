import React from 'react';

import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime as LuxonDateTime } from 'luxon';

import CalendarIcon from '../Icon/CalendarIcon';
import { DATETIME_FORMAT } from './constants';
import { getFormattedDateTime } from './helpers';

// For reference, refer to components/common/DateTime/README.md
const DateTime = ({
  customFormat = '',
  endTime = '',
  format = DATETIME_FORMAT.friendly,
  locale = '',
  timestamp,
  timezone = '',
  round,
  withIcon = false,
  color = 'grey.700',
  withPopover = true,
  ...props
}) => {
  const { fdnsLocalDatetime } = useFlags();

  if (fdnsLocalDatetime === undefined || !timestamp) {
    return null;
  }

  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  const defaultTimezone = fdnsLocalDatetime
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : 'UTC';
  const defaultLocale = fdnsLocalDatetime ? userLocale : 'en-US';

  timezone = timezone.length ? timezone : defaultTimezone;
  locale = locale.length ? locale : defaultLocale;

  let dateTimeIso, title, isValid;

  if (
    format === DATETIME_FORMAT.duration ||
    format === DATETIME_FORMAT.duration_short
  ) {
    const endDateTime = LuxonDateTime.fromISO(endTime).setZone(timezone);
    const startDateTime = LuxonDateTime.fromISO(timestamp).setZone(timezone);
    isValid = startDateTime.isValid && endDateTime.isValid;
    dateTimeIso = endDateTime.diff(startDateTime).toISO();

    title = 'Elapsed Time';
  } else if (format === DATETIME_FORMAT.date_range) {
    const endDateTime = LuxonDateTime.fromISO(timestamp[0]).setZone(timezone);
    const startDateTime = LuxonDateTime.fromISO(timestamp[1]).setZone(timezone);
    isValid = startDateTime.isValid && endDateTime.isValid;
  } else {
    const dateTime = LuxonDateTime.fromISO(timestamp).setZone(timezone);
    dateTimeIso = dateTime.toISO();
    isValid = dateTime.isValid;
    title = getFormattedDateTime(timestamp, DATETIME_FORMAT.friendly, {
      timezone: timezone,
      locale: locale,
    });
  }

  if (isValid && withPopover) {
    return (
      <Box display="inline-block">
        <Popover trigger="hover" placement="bottom-start">
          <PopoverTrigger>
            <time dateTime={dateTimeIso}>
              <Flex alignItems="center" color={color} {...props}>
                {withIcon && <CalendarIcon mr="1" />}
                {getFormattedDateTime(timestamp, format, {
                  endTime: endTime,
                  customFormat: customFormat,
                  timezone: timezone,
                  locale: locale,
                  round,
                })}
              </Flex>
            </time>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <time dateTime={dateTimeIso} title={title}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  color={color}
                  borderBottom="1px"
                  borderBottomColor="gray.300"
                  p={2}
                >
                  <Text size={6} fontWeight={600} mb={0}>
                    UTC
                  </Text>
                  <Text size={6} mb={0}>
                    {getFormattedDateTime(timestamp, 'custom', {
                      customFormat: 'MM/dd/yyyy, hh:mm a',
                      timezone: 'UTC',
                      locale: locale,
                      round,
                    })}
                  </Text>
                </Flex>
              </time>
              <time dateTime={dateTimeIso} title={title}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  color={color}
                  p={2}
                >
                  <Text size={6} fontWeight={600} mb={0}>
                    {`Local (UTC${LuxonDateTime.fromISO(timestamp).toFormat(
                      'Z',
                    )})`}
                  </Text>
                  <Text size={6} mb={0}>
                    {getFormattedDateTime(timestamp, 'custom', {
                      customFormat: 'MM/dd/yyyy, hh:mm a',
                      timezone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      locale: locale,
                      round,
                    })}
                  </Text>
                </Flex>
              </time>
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
    );
  }
  if (isValid && withIcon) {
    return (
      <time dateTime={dateTimeIso} title={title}>
        <Flex alignItems="center" color={color} {...props}>
          <CalendarIcon mr="1" />
          {getFormattedDateTime(timestamp, format, {
            endTime: endTime,
            customFormat: customFormat,
            timezone: timezone,
            locale: locale,
            round,
          })}
        </Flex>
      </time>
    );
  } else if (isValid) {
    return (
      <time dateTime={dateTimeIso} title={title}>
        {getFormattedDateTime(timestamp, format, {
          endTime: endTime,
          customFormat: customFormat,
          timezone: timezone,
          locale: locale,
          round,
        })}
      </time>
    );
  } else {
    return <Text as="span">Invalid date/time format</Text>;
  }
};

export default DateTime;
