import firehydrantAPI from 'helpers/firehydrantAPI';
import internalAPI from 'helpers/internalAPI';
import { useMutation, useQuery } from 'react-query';

export const useSignals = (params = {}) => {
  const query = useQuery(['signalsList', params], () =>
    firehydrantAPI.get('signals', { params }),
  );
  return {
    ...query,
    data: query?.data?.data?.data || [],
    pagination: query?.data?.data?.pagination || {},
  };
};

export const useCreateSignal = (url) =>
  useMutation((params) => firehydrantAPI.post(url, params));

export const useSignalDebugger = (options) =>
  useMutation(
    (params) => firehydrantAPI.post('signals/debugger', params),
    options,
  );

export const useSignalCurlUrl = () => {
  const query = useQuery('signalCurlUrl', () =>
    internalAPI.get('/internal_api/v1/onboarding/signals/ingest_url'),
  );
  return {
    ...query,
    data: query?.data?.data || {},
  };
};
