import React from 'react';

import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@firehydrant/design-system';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const FormikCheckbox = ({
  display = 'flex',
  name,
  disabled,
  helperText,
  mb = 0,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <>
          <Checkbox
            isChecked={field.value && !disabled}
            isDisabled={disabled}
            {...field}
            {...props}
            mb={mb}
          />
          <FormControl
            mb={mb}
            isInvalid={meta.touched && meta.error}
            display={display}
          >
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {meta.touched && meta.error && (
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            )}
          </FormControl>
        </>
      )}
    </Field>
  );
};

FormikCheckbox.propTypes = {
  display: PropTypes.string,
  name: PropTypes.string.isRequired,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

export default FormikCheckbox;
