import firehydrantAPI from 'helpers/firehydrantAPI';
import { createMutationHook, useListQuery } from 'queries/common';
import { useMutation, useQuery } from 'react-query';

export const useSignalNotifications = ({ notificationPriority = 'HIGH' }) => {
  const query = useQuery(['signals_notification_settings', notificationPriority], () =>
    firehydrantAPI.get('signals_notification_settings', {params: { notification_priority: notificationPriority} }),
  );

  return {
    ...query,
    data: query.data?.data?.data,
  };
};

export const useSignalDestinations = () => {
  const query = useQuery(['signals_notification_destinations'], () =>
    firehydrantAPI.get('signals/destinations', {}),
  );

  return {
    ...query,
    data: query.data?.data?.data,
  };
};

export const createSignalsNotification = (params) =>
  firehydrantAPI.post('signals_notification_settings', params);

export const useCreateSignalsNotification = (params) =>
  useMutation(createSignalsNotification, params);

const updateSignalsNotification = (params) =>
  firehydrantAPI.patch(`signals_notification_settings/${params.id}`, params);

export const useUpdateSignalsNotification = (params) => {
  return useMutation(updateSignalsNotification, params);
};

const deleteSignalsNotification = (params) =>
  firehydrantAPI.delete(`signals_notification_settings/${params.id}`);

export const useDeleteSignalsNotification = (params) => {
  return useMutation(deleteSignalsNotification, params);
};

const RESOURCE_ARRAY = () => [
  { resource: 'signals', id: 'handoff_notification_settings' },
];

const signalsHandoffNotifications = () =>
  firehydrantAPI.get('signals/handoff_notification_settings', {});

export const useSignalsHandoffNotifications = () =>
  useListQuery(RESOURCE_ARRAY(), {}, signalsHandoffNotifications);

const createHandoffNotification = (body) =>
  firehydrantAPI.post(`signals/handoff_notification_settings`, body);

export const useCreateHandoffNotification = createMutationHook(
  createHandoffNotification,
);

const updateHandoffNotification = (params) =>
  firehydrantAPI.patch(
    `signals/handoff_notification_settings/${params.id}`,
    params,
  );

export const useUpdateHandoffNotification = createMutationHook(
  updateHandoffNotification,
);

const deleteHandoffNotification = (id) =>
  firehydrantAPI.delete(`signals/handoff_notification_settings/${id}`);

export const useDeleteHandoffNotification = createMutationHook(
  deleteHandoffNotification,
);
