import React, { useEffect, useState } from 'react';

import {
  Circle,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  forwardRef,
} from '@chakra-ui/react';
import { Flex, Icon } from '@firehydrant/design-system';
import propTypes from 'prop-types';
import { TbCheck, TbMinus } from 'react-icons/tb';

import ToolbarPopover from './ToolbarPopover';

const Health = () => {
  const [data, setData] = useState(null);
  const [isHealthy, setHealthy] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const healthCheck = async () => {
    const body = await fetch('/infra/health')
      .then((response) => response.json())
      .finally(() => setLoading(false));
    setData(body);
    setHealthy(
      body.healthy &&
        body.components.database.healthy &&
        body.components.redis.healthy,
    );
  };

  const HealthTag = ({ healthy, message }) => {
    return (
      <Tooltip hasArrow label={healthy ? 'All okay' : message}>
        <Tag
          colorScheme={healthy ? 'green' : 'red'}
          fontWeight="500"
          px={2}
          py={1}
          mr={2}
          borderRadius="md"
          fontFamily="mono"
        >
          <TagLabel fontSize="xs">{healthy ? 'healthy' : 'unhealthy'}</TagLabel>
        </Tag>
      </Tooltip>
    );
  };

  const HealthPopoverTrigger = forwardRef((props, ref) => {
    return (
      <Circle
        size="20px"
        backgroundColor={isHealthy ? 'green' : 'red'}
        color="grey.0"
        _hover={{ cursor: 'pointer' }}
        ref={ref}
      >
        <Icon as={isHealthy ? TbCheck : TbMinus} boxSize="16px" />
      </Circle>
    );
  });

  useEffect(() => {
    healthCheck();
    const healthInterval = setInterval(healthCheck, 60000);

    return () => clearInterval(healthInterval);
  }, []);

  if (isLoading) return <Circle size="20px" backgroundColor="grey.300" />;

  return (
    <ToolbarPopover trigger={<HealthPopoverTrigger />}>
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" fontSize="sm" color="grey.300">
          <HealthTag healthy={data.healthy} /> App &ndash; pod{' '}
          <Text as="span" fontFamily="mono">
            {' '}
            {data.hostname}
          </Text>
        </Flex>
        <Flex alignItems="center" fontSize="sm" color="grey.300">
          <HealthTag
            healthy={data.components.database.healthy}
            message={data.components.database.message}
          />{' '}
          Database
        </Flex>
        <Flex alignItems="center" fontSize="sm" color="grey.300">
          <HealthTag
            healthy={data.components.redis.healthy}
            message={data.components.redis.message}
          />{' '}
          Redis
        </Flex>
      </Flex>
    </ToolbarPopover>
  );
};

Health.propTypes = {
  healthy: propTypes.bool,
  message: propTypes.string,
};

export default Health;
