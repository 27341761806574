import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  ExternalLinkIcon,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@firehydrant/design-system';
import PropTypes from 'prop-types';

import { ENTITLEMENT_TIERS, ENTITLEMENT_TITLE } from '../constants';
import { formatTier, formatTierWithArticle, formatTitle } from '../helpers';

const EntitlementsModal = ({ isOpen, onClose, quotaLimit, name, tier }) => {
  return (
    <Box data-testid="entitlements-modal">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalCloseButton
            data-testid="entitlements-modal-close-button"
            background="transparent"
            top="20px"
            right="3"
            size="lg"
          />
          <Text as="h3" size={4}>
            Oh no! You&apos;ve reached the limit!
          </Text>
          <Text data-testid="entitlements-modal-body">
            You can only create {quotaLimit} {formatTitle(name, quotaLimit)} on{' '}
            {formatTierWithArticle(tier)} plan. Reach out to the owner of the
            subscription to upgrade your plan.
          </Text>
          <Flex justifyContent="flex-end" width="100%">
            <ButtonGroup>
              <Button
                data-testid="entitlements-modal-cancel-button"
                variant="ghost"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                as="a"
                href="https://firehydrant.com/pricing/"
                rightIcon={<ExternalLinkIcon boxSize={4} />}
                rel="noopener"
                target="_blank"
              >
                View pricing plans
              </Button>
            </ButtonGroup>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
};

EntitlementsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  quotaLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOf(Object.keys(ENTITLEMENT_TITLE)).isRequired,
  tier: PropTypes.oneOf([ENTITLEMENT_TIERS.essentials, ENTITLEMENT_TIERS.free]),
};

export default EntitlementsModal;
