import React from 'react';

import { Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { PlusIcon } from '@firehydrant/design-system';
import { getAPIError } from 'helpers/firehydrantAPI';
import { TEAM_DETAIL, useTeam, useUpdateTeam } from 'queries/teams';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import AlertBar from 'components/common/AlertBar';
import AutomationWarning from 'components/common/AutomationWarning';
import FormV2 from 'components/common/Form/FormV2/FormV2';
import Modal from 'components/common/Modals/Modal';
import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';

import { userAndScheduleSearchOptions } from '../TeamsEdit/TeamUsersDropdownHelpers';

const validationSchema = Yup.object().shape({
  memberships: Yup.array().of(
    Yup.object().shape({
      user: Yup.object().required('Required'),
    }),
  ),
});

const TeamAddMembersForm = () => {
  const { id: teamId } = useParams();
  const { data: team } = useTeam(teamId);
  const { addToast } = useToastContext();
  const [apiError, setApiError] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const { mutate: updateTeam, isLoading: isSubmitting } = useUpdateTeam({
    onSuccess: () => {
      addToast({
        variant: 'success',
        message: 'Team members updated successfully',
      });
      setIsOpen(false);
      queryClient.invalidateQueries([TEAM_DETAIL, teamId]);
    },
    onError: (err) => {
      const message = getAPIError(err);
      setApiError(message);
    },
  });

  return (
    <>
      <Button
        leftIcon={<PlusIcon w="16px" />}
        variant="outline"
        onClick={() => setIsOpen(true)}
      >
        Add members
      </Button>
      <Modal
        modalWidth="800px"
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        headerText="Add members"
        showFooter={false}
      >
        <FormV2
          initialValues={{
            memberships: [{ user: '' }],
          }}
          onSubmit={(values) => {
            const membershipIds = values.memberships.reduce(
              (acc, membership) => {
                acc.add(membership?.user?.value?.id);
                return acc;
              },
              new Set(),
            );
            const memberships = team.memberships
              ?.reduce((acc, membership) => {
                if (
                  !membershipIds.has(membership?.user?.id) &&
                  !membershipIds.has(membership?.schedule?.id)
                ) {
                  acc.push({
                    user_id: membership?.user?.id,
                    schedule_id: membership?.schedule?.id,
                    incident_role_id: membership?.default_incident_role?.id,
                  });
                }
                return acc;
              }, [])
              .concat(
                values.memberships.map((membership) => {
                  if (membership?.user?.value?.type === 'user') {
                    return {
                      user_id: membership?.user?.value?.id,
                      incident_role_id:
                        membership?.default_incident_role?.value,
                    };
                  }
                  return {
                    schedule_id: membership?.user?.value?.id,
                    incident_role_id: membership?.default_incident_role?.value,
                  };
                }),
              );

            updateTeam({
              id: teamId,
              memberships,
            });
          }}
          validationSchema={validationSchema}
        >
          <>
            {apiError && (
              <AlertBar isOpen onClose={() => setApiError(null)}>
                {apiError}
              </AlertBar>
            )}
            <FormV2.Group>
              <FormV2.FieldArray name="memberships" title="Memberships">
                {(arrayHelpers) => {
                  const memberships = arrayHelpers.form.values.memberships;
                  return (
                    <>
                      {arrayHelpers.form.values.memberships.map(
                        (item, index) => (
                          <FormV2.ArrayTwoColumn key={index}>
                            <FormV2.Dropdown
                              name={`memberships[${index}].user`}
                              data-testid={`memberships[${index}].user`}
                              label="Member name"
                              arrayItemIndex={index}
                              type="async"
                              customLoadOptions={userAndScheduleSearchOptions}
                              filterOption={(option) => {
                                return !memberships
                                  .map((member) => member.user?.value?.id)
                                  .includes(option.value.id);
                              }}
                            />
                            <FormV2.Dropdown
                              name={`memberships[${index}].default_incident_role`}
                              label="Role"
                              arrayItemIndex={index}
                              type="async-paginate"
                              asyncUrl="incident_roles"
                              placeholder="Choose a role"
                            />
                            {index > 0 ? (
                              <FormV2.ArrayDeleteIconButton
                                removeAction={() => arrayHelpers.remove(index)}
                                index={index}
                              />
                            ) : (
                              <Flex w="70px" h="20px" />
                            )}
                          </FormV2.ArrayTwoColumn>
                        ),
                      )}
                      <FormV2.ArrayAddButton
                        buttonText="Add member"
                        addAction={() => arrayHelpers.push({ user: '' })}
                      />
                    </>
                  );
                }}
              </FormV2.FieldArray>
            </FormV2.Group>
            <Flex justifyContent="space-between">
              <AutomationWarning createdBy={team.created_by} />
              <ButtonGroup justifySelf="flex-end">
                <Button onClick={() => setIsOpen(false)} variant="ghost">
                  Cancel
                </Button>
                <Button type="submit" isLoading={isSubmitting}>
                  Add Members
                </Button>
              </ButtonGroup>
            </Flex>
          </>
        </FormV2>
      </Modal>
    </>
  );
};

export default TeamAddMembersForm;
