import firehydrantAPI from 'helpers/firehydrantAPI';
import { useMutation, useQuery } from 'react-query';

export const TEAMS_LIST = 'TEAMS_LIST';
export const TEAM_DETAIL = 'TEAM_DETAIL';

export const useTeams = (params, options = {}) => {
  const query = useQuery(
    [TEAMS_LIST, params],
    () => firehydrantAPI.get('teams', { params }),
    options,
  );

  return {
    ...query,
    data: query?.data?.data?.data || [],
    pagination: query?.data?.data?.pagination || {},
  };
};

export const useTeam = (id, options = {}) => {
  const query = useQuery(
    [TEAM_DETAIL, id, options],
    () => firehydrantAPI.get(`teams/${id}`),
    { enabled: !!id, ...options },
  );
  return {
    ...query,
    data: query?.data?.data || {},
  };
};

const createTeam = (body) => firehydrantAPI.post('teams', body);

export const useCreateTeam = (body) => useMutation(createTeam, body);

const updateTeam = (body) => firehydrantAPI.patch(`/teams/${body.id}`, body);

export const useUpdateTeam = (body) => {
  return useMutation(updateTeam, body);
};

const deleteTeam = (id) => firehydrantAPI.delete(`teams/${id}`);

export const useDeleteTeam = (id) => useMutation(deleteTeam, id);
