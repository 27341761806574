import React from 'react';

import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateCurrentUser } from 'queries/users';

import NotificationSettingsForm from 'components/notification_settings/NotificationSettingsForm';

import { useToastContext } from '../common/ToastBannerProvider/ToastBannerProvider';
import LinkedAccounts from './LinkedAccounts';
import SignalsNotificationSettings from '../account_v2/SignalsNotificationSettings';
import SignalsCalendarSubscriptionSelector from './SignalsCalendarSubscriptionSelector';

const OrganizationSettingsPage = ({ isViewingDefaultOrg }) => {
  const { signals } = useFlags();
  const { currentOrganization, currentUserOrganizations, refetch } =
    useUserContext();
  const { addToast } = useToastContext();
  const onSuccess = () => {
    refetch();
    addToast({
      message: 'Your default organization has been updated.',
      variant: 'success',
    });
  };
  const onError = () => {
    addToast({
      message: 'Something went wrong updating default organization.',
      variant: 'alert',
    });
  };
  const { mutate: mutateUpdateUser } = useUpdateCurrentUser({
    onError,
    onSuccess,
  });

  const handleSubmit = () => {
    let params = {
      default_organization_id: currentOrganization?.node?.id,
    };

    mutateUpdateUser(params);
  };

  const showDefaultBadge =
    currentUserOrganizations?.length > 1 && isViewingDefaultOrg;

  return (
    <Box pt={4} mb={4}>
      <Flex align="center">
        <Text as="h3" color="grey.900" size={3} mb={0}>
          {currentOrganization?.node?.name}{' '}
        </Text>
        {showDefaultBadge && (
          <Tag size="sm" ml="4" variant="outline" bg="white">
            Default
          </Tag>
        )}
      </Flex>
      {!isViewingDefaultOrg && (
        <Tag
          cursor="pointer"
          onClick={handleSubmit}
          mt={3}
          size="sm"
          variant="outline"
          bg="white"
        >
          Set as default organization
        </Tag>
      )}
      <LinkedAccounts />
      {signals && <SignalsNotificationSettings />}
      {signals && <SignalsCalendarSubscriptionSelector />}
    </Box>
  );
};

export default OrganizationSettingsPage;
