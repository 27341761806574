import { useMutation, useQuery } from 'react-query';

import firehydrantAPI from '../../helpers/firehydrantAPI';

export function usePhones(params) {
  const requestParams = { params: { per_page: 20, ...params } };

  const { data: requestData = { data: { data: [] } }, ...query } = useQuery(
    ['phones', requestParams],
    () => firehydrantAPI.get('/phones', requestParams),
  );

  return {
    ...query,
    data: requestData?.data?.data,
    pagination: requestData?.data?.pagination || {},
  };
}

const createPhone = (body) => firehydrantAPI.post(`/phones`, body);
export const useCreatePhone = (body) => useMutation(createPhone, body);

const deletePhone = (body) => firehydrantAPI.delete(`/phones/${body.id}`, body);
export const useDeletePhone = (body) => useMutation(deletePhone, body);

const verifyPhone = (body) =>
  firehydrantAPI.put(`/phones/${body.id}/verify`, body);
export const useVerifyPhone = (body) => useMutation(verifyPhone, body);

const resendPhone = (body) =>
  firehydrantAPI.put(`/phones/${body.id}/resend`, body);
export const useResendPhone = (body) => useMutation(resendPhone, body);
