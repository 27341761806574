import React, { useState } from 'react';

import { Button, Card, Flex, Icon, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useSignalDestinations } from 'queries/signalsNotifications';
import { FiInfo } from 'react-icons/fi';

import OrgRouteLink from 'components/common/OrgRouteLink';

import PhoneVerificationModal from './PhoneVerificationModal';
import SignalsNotificationsForm from './SignalsNotificationForm';

const PhonePromptMessage = ({ refetchDestinations }) => {
  const [verifyNumber, setVerifyNumber] = useState();

  return (
    <>
      <Flex flexDir="column" mt="4">
        <Card
          p="3"
          mb="4"
          position="relative"
          bgColor="yellow.50"
          borderColor="yellow.300"
        >
          <Flex justifyContent="space-between">
            <Flex gap="2" alignItems="baseline">
              <Icon color="gray.700" as={FiInfo} boxSize="16px" />
              <Flex flexDir="column" alignItems="flex-start">
                <Text size="5" as="p" color="gray.700" mb="0">
                  You need a verified phone number added to your account to
                  recieve text and voice notifications.
                </Text>
                <Button
                  size="sm"
                  mt="3"
                  onClick={() => setVerifyNumber({ open: true })}
                >
                  Add a phone number
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <PhoneVerificationModal
        refetch={refetchDestinations}
        reverify={false}
        setReverify={() => {}}
        verifyNumber={verifyNumber}
        setVerifyNumber={setVerifyNumber}
      />
    </>
  );
};

const SignalsNotificationSettings = () => {
  const { data: destinations, refetch } = useSignalDestinations();

  const noVerifiedPhone =
    destinations?.filter((destination) => destination.type === 'phone')
      .length === 0;
  return (
    <>
      <Flex flexDir="column" mt="4">
        <Text color="gray.700" size="4" mb="2">
          Alert Notifications
        </Text>
        <Text fontSize="5" color="gray.700">
          Choose a notification type and time to configure how you are notified
          when an alert is assigned to you. Add phone numbers, additional
          emails, and link third-party accounts in the{' '}
          <Link to="/account/edit" as={OrgRouteLink}>
            Profile
          </Link>{' '}
          tab.
        </Text>
      </Flex>

      {noVerifiedPhone && <PhonePromptMessage refetchDestinations={refetch} />}

      <Flex
        flexDir="row"
        justifyContent="space-between"
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        gap="2"
      >
        <Flex
          flexDir="column"
          bgColor="white"
          border="1px"
          borderColor="grey.200"
          width={{ base: '100%', lg: '33%' }}
          borderRadius={10}
          p="2"
        >
          <Text color="gray.700" size="4" mb="2" ml="0.5">
            High Priority
          </Text>
          <SignalsNotificationsForm
            notificationPriority="HIGH"
            destinations={destinations}
          />
        </Flex>
        <Flex
          flexDir="column"
          bgColor="white"
          border="1px"
          borderColor="grey.200"
          width={{ base: '100%', lg: '33%' }}
          borderRadius={10}
          p="2"
        >
          <Text color="gray.700" size="4" mb="2" ml="0.5">
            Medium Priority
          </Text>
          <SignalsNotificationsForm
            notificationPriority="MEDIUM"
            destinations={destinations}
          />
        </Flex>
        <Flex
          flexDir="column"
          bgColor="white"
          border="1px"
          borderColor="grey.200"
          width={{ base: '100%', lg: '33%' }}
          borderRadius={10}
          p="2"
        >
          <Text color="gray.700" size="4" mb="2" ml="0.5">
            Low Priority
          </Text>
          <SignalsNotificationsForm
            notificationPriority="LOW"
            destinations={destinations}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default SignalsNotificationSettings;

PhonePromptMessage.propTypes = {
  destinations: PropTypes.array.isRequired,
};
