import React from 'react';

import { Text } from '@chakra-ui/react';
import { Alert } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

export function LocalError({ error }) {
  return (
    <Alert variant="alert">
      <Text as="p" fontWeight="bold">
        Oops! There was an unexpected error.
      </Text>
      {error && <Text as="p">{error.message}</Text>}
    </Alert>
  );
}

LocalError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};
